import React, {useState}  from "react"
import { Link } from "gatsby"

// import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";

import CheckIcon from '@material-ui/icons/Check';

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

// App bar test
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";

// Menu
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import "../../components/layout.css";


export default function OliviaReaney () { 

    function openMail(){
        var email = "eric@campbelldatascience.com";
        var subject = "CDS Infographics";
        {/* var emailBody = 'Hi Eric'; */}
        document.location = "mailto:"+email+"?subject="+subject;
        handleClose();
      }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  

return (


  <div style={{
          margin: `0 auto`,
          //maxWidth: 750,
          //padding: `0 1.0875rem 1.45rem`
        }}>


        {/* APP BAR */}
      <div style={{paddingBottom: "25px"}}>
        <AppBar
          elevation={0}
          position="static"
          style={{ backgroundColor: "steelBlue", height: "60px" }}
        >
        

        <div style={{}}>
          <Toolbar style={{ paddingRight: "0px", paddingLeft: "10px", maxWidth:"1200px", marginLeft:"auto", marginRight:"auto"}}>

          <Grid
        container
        direction="row"
        justify="space-between"
        spacing={1}
        style={{
          alignItems: "center"
        }}
      >
          

      <Grid
          item
          xs={6}
          sm={6}
          style={{ textAlign: "left"}}
        >
            <Typography variant="h6">
            <Link to="/infographics/" style={{ textDecoration: "none", color: "#d6ebf2" }}>
              CDS
            </Link>
            </Typography>
        </Grid>


        <Grid
          item
          xs={6}
          sm={6}
          style={{ textAlign: "right"}}
        >
            {/* Button inserted... */}
            <div style={{ marginLeft: "auto" }}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ paddingRight: "0px" }}
              >
                <IconButton
                  edge="start"
                  aria-label="menu"
                  style={{ color: "#d6ebf2" }}
                >
                  <MenuIcon />
                </IconButton>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
            
              <a
                  href="http://oliviareaney.com/contact"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                <MenuItem onClick={handleClose}>contact olivia</MenuItem>
              </a>
                
                <a
                  href="http://oliviareaney.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleClose}>olivia's website</MenuItem>
                </a>


                <MenuItem onClick={openMail}>contact us</MenuItem>

                {/* <Link to="/infographics/" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>go back</MenuItem>
              </Link> */}

              </Menu>
            </div>
            {/* ....Button ended */}
        </Grid>

        </Grid>

          </Toolbar>


          </div>

          


        </AppBar>
      </div>
      


    <SEO
      title="Olivia Reaney"
      description="Graphic Designer Olivia Reaney who specialises in Infographics takes us through what she needs from a client to get the best results"
    />

    {/* Content div exluding bar */}
    <div style={{paddingLeft: "10px", paddingRight:"10px", paddingBottom:"10px", maxWidth:"750px", marginLeft:"auto", marginRight:"auto"}}>

    <Grid
        container
        justify="space-evenly"
        spacing={1}
        style={{
          paddingBottom: "0px",

        }}
      >
      <Grid
          item
          xs={7}
          sm={7}
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >
        <h1>Olivia Reaney</h1>
      </Grid>

      <Grid
          item
          xs={5}
          sm={5}
          style={{ textAlign: "right", paddingBottom: "10px" }}
        >
        <div style={{ marginLeft: "auto" }}>
        <a
      href="http://oliviareaney.com/contact"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
        <Button
              variant="contained"
              // color="primary"
              disableElevation
              style={{
                backgroundColor: "steelBlue",
                color: "#d6ebf2"
              }}
            >
              hire now
              <CheckIcon style={{ paddingLeft: "8px", paddingBottom: "2px" }} />
            </Button>
            </a>
            </div>
      </Grid>

    </Grid>




    <Grid
        container
        justify="space-between"
        spacing={1}
        style={{
          paddingBottom: "0px",

        }}
      >

      <Grid
          item
          xs={12}
          sm={7}
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >

        <h2 style={{color:"steelBlue", fontSize:"1.2rem", fontWeight:"600"}}>My name is Olivia and I am a SF based graphic designer. I specialize in infographic and help brands tell their stories. I started my own business two years ago after gaining great experience at two NYC agencies. I have worked with a variety of clients in different areas including tech, healthcare, beauty, food, and others.</h2>
    

        </Grid>

      <Grid
          item
          xs={12}
          sm={4}
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >
        <div style={{ textAlign: "center" }}>
    <img
              src="https://i.imgur.com/LGHkbfm.jpg"
              alt="Olivia Reaney Profile picture"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                // height: "60%",
                maxWidth: "100%"                
              }}
            />
    </div>
        </Grid>

        
    
      </Grid>



    
    <h3 style={{fontWeight:"600"}}>How did you get into graphic design?</h3>
    <p>I have always had a niche for storytelling and aesthetically pleasing presentations. Growing up, my mom worked at an agency where I was first exposed to graphic design. I then found a great program where I majored in infographic and publication design. They were unique from other traditional graphic design programs because we learned more about the story telling aspect of design.</p>
    
    <h3 style={{fontWeight:"600"}}>What do infographics mean to you? </h3>
    <p>Infographics are the visual language to storytelling. They can oftentimes more quickly and effectively tell a story compared to written text.</p>

    <h3 style={{fontWeight:"600"}}>How do you get inspired?</h3>
    <p>I am always saving inspiration that I find on different blogs and just generally around the web. I archive all my samples and oftentimes reference them. I am also inspired by my friends who are designers and we often share our work.</p>

    <h3 style={{fontWeight:"600"}}>Can you outline your progress for designing an infographic?</h3>
    <p>I always start with a call to make sure I understand what the company does and what they are trying to achieve with the infographic. From there I make sure to gather all of their brand materials and start with a wireframes of the content. This includes a black and white version including all of the text and placeholders for icons and illustrations. Once we are aligned with that, I go into design. I sometimes like to review after each section is completed.</p>

    <h3 style={{fontWeight:"600"}}>What should a client give you to get the best results?</h3>
    <p>I have a content template that I often provide that ensures the copy is well organized for infographic form. I also need brand guidelines and what the infographic will be used for (web, print, etc).</p>

    <h3 style={{fontWeight:"600"}}>What are your favourite types of jobs to work on?</h3>
    <p>I love data heavy infographics. It is always a challenge to figure out how to best display information in a way that is appealing yet accurate.</p>

    <h3 style={{fontWeight:"600"}}>What do you like to do when you are not designing?</h3>
    <p>I like to be outside walking my dogs or at the park. I love music and like to see it live or play my guitar.</p>



    <div style={{ paddingBottom: "35px", textAlign:"center" }}>
    <a
      href="http://oliviareaney.com/contact"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
        <Button
              variant="contained"
              // color="primary"
              disableElevation            
              style={{
                width: "35ch",
                backgroundColor: "#D8564A",
                color: "white",
              }}
            >
              Contact Olivia 
              <EmojiPeopleIcon style={{ paddingLeft: "8px", paddingBottom: "2px" }} />
            </Button>
          </a>
          </div>




          <Link to="/infographics/" style={{ textDecoration: "none" }}>
      Go back to the infographics homepage
    </Link>

    </div>




    <footer
          style={{
            fontFamily: "Arial, Helvetica, sansSerif",
            fontSize: "0.9rem",
            color: "#3d3d3d",
            paddingBottom:"10px"                    
          }}
        >
        <Grid
        container
        direction="row"
        spacing={0}
        justify="center" 
        style={{maxWidth: "750px", marginLeft:"auto", marginRight:"auto"}}       
      >

      <Grid item xs={6} style={{textAlign: "left", paddingLeft:"8px"}}>
  
          <Link
            to="/about/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            {/* © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.gatsbyjs.org">International Shopper</a> */}
            © {new Date().getFullYear()} CDS
          </Link>

        </Grid>

        <Grid item xs={6} style={{textAlign: "right", paddingRight:"8px"}}>
        
          <Link
            to="/privacy-policy/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            Privacy
          </Link>

        </Grid>

        </Grid>
        </footer>

    </div>
    );
}

